<template>
  <div class="q-pa-md flex flex-center">
    <div>
      <h1>404 - Not Found</h1>
      <p>
        The resource <b>{{ pathname }}</b> is unknown.
      </p>
      <p>
        If you think this is an error, please click the Report button below to
        escalate this event to the webmaster.
      </p>
      <q-btn
        no-caps
        color="primary"
        @click="notify"
        label="Report to webmaster"
      />
    </div>
  </div>
</template>
<script>
import { API } from "../tic";
export default {
  name: "NotFound",
  computed: {
    pathname() {
      return document.location.pathname;
    },
  },
  mounted() {
    // TODO
    // client could report 404 to api here instead of manually
  },

  methods: {
    notify() {
      const vm = this;
      API.post("/log/notify-webmaster", {
        code: 404,
        msg: `${this.pathname} not found`,
      }).then((e) => {
        vm.$router.push("/");
      });
    },
  },
};
</script>